import { t } from '@lingui/macro'
import { v4 } from 'uuid'
import {
  AddAudioParams,
  AudioProcessed,
  AudioProcessedOperation
} from '../../../types/audio-processed.types'
import {
  OperationMasterResult,
  TaskOperations,
  TaskSummary
} from '../../../types/task.types'
import { config } from '../../../config'

const getFakeOriginalOperation = (): AudioProcessed => {
  return {
    id: 'original',
    group: 9999,
    type: 'original',
    icon: 'mastering',
    name: 'Original',
    isOriginal: true,
    operations: [
      {
        id: v4(),
        status: 'STARTED',
        result: {
          m4a: undefined,
          flac: undefined
        }
      }
    ]
  }
}

export const getOriginalAudio = (
  summary: TaskSummary,
  operations: TaskOperations[]
): AudioProcessed => {
  const operation = operations?.find(
    (i) => i.name === 'ENCODE_A' && i.status === 'COMPLETED'
  )

  if (
    !operation ||
    (!summary?.v1?.original?.url?.flac && !summary?.v1?.original?.url?.m4a)
  ) {
    return getFakeOriginalOperation()
  }

  return {
    id: 'original',
    group: 9999,
    type: 'original',
    icon: 'mastering',
    name: 'Original',
    isOriginal: true,
    operations: [
      {
        id: 'original-operation',
        status: 'COMPLETED',
        result: {
          m4a: summary.v1.original.url?.m4a,
          flac: summary.v1.original.url?.flac
        }
      }
    ]
  }
}

const getInfoAdvancedMode = (
  params: AudioProcessedOperation['params']
): string => {
  return `
    ${params?.ceiling ? `Level: ${params?.ceiling} |` : ''}
    ${params?.lowCutFrequency ? `Low Cut: ${params?.lowCutFrequency} |` : ''}
    ${params?.highCutFrequency ? `High Cut: ${params?.highCutFrequency}` : ''}
  `
}

const getInfoReferenceMode = (
  params: AudioProcessedOperation['params']
): string => {
  return `
    ${params?.referenceAudio?.name ? `${params?.referenceAudio?.name} |` : ''}
    ${params?.algorithm ? `Version: ${params?.algorithm}` : ''}
  `
}

export const getOperationData = ({
  operation,
  isAdvancedMode,
  isReferenceMode
}: {
  operation: TaskOperations
  isAdvancedMode?: boolean
  isReferenceMode?: boolean
}): AudioProcessedOperation => {
  let files: AudioProcessedOperation['result']
  const result = operation?.result as OperationMasterResult

  if (result?.files) {
    files = {
      m4a: !result?.files?.mastered_m4a
        ? undefined
        : `${config.api.endpoint}/v3/download${result?.files?.mastered_m4a}`,
      flac: !result?.files?.mastered_flac
        ? undefined
        : `${config.api.endpoint}/v3/download${result?.files?.mastered_flac}`
    }
  }

  const params: {
    [key: string]: any
  } = {
    lowCutFrequency: operation.params?.lowCutFrequency,
    highCutFrequency: operation.params?.highCutFrequency,
    masteringIntensity: operation.params?.masteringIntensity,
    ceiling: operation.params?.ceiling,
    targetLoudness: operation.params?.targetLoudness,
    referenceAudio: operation.params?.referenceAudio,
    algorithm: operation.params?.algorithm
  }
  const info = isReferenceMode
    ? getInfoReferenceMode(params)
    : isAdvancedMode
    ? getInfoAdvancedMode(params)
    : undefined

  return {
    id: operation.id,
    status: operation.status,
    nameOperation: operation.name,
    info,
    params,
    result: files
  }
}

const getNameOperation = ({
  isReferenceMode,
  isLegacy,
  isAdvancedMode
}: {
  isReferenceMode: boolean
  isLegacy: boolean
  isAdvancedMode: boolean
}): string => {
  return isReferenceMode || isLegacy
    ? t`Reference Track`
    : isAdvancedMode
    ? t`Advanced Mode`
    : t`Auto Mode`
}

const getTypeOperation = ({
  isReferenceMode,
  isLegacy,
  isAdvancedMode
}: {
  isReferenceMode: boolean
  isLegacy: boolean
  isAdvancedMode: boolean
}): string => {
  return isReferenceMode || isLegacy
    ? 'Reference'
    : isAdvancedMode
    ? 'Advanced'
    : 'Auto'
}

export const getProcessedAudios = ({
  operations,
  localOperations,
  deletedOperations
}: {
  operations: TaskOperations[]
  localOperations?: AddAudioParams[]
  deletedOperations?: AudioProcessedOperation[]
}): AudioProcessed[] => {
  if (!operations || !operations.length) {
    return []
  }

  const list: AudioProcessed[] = []

  const deletedOperationIds = deletedOperations?.map((i) => i.id) || []

  operations
    .filter((operation) => !deletedOperationIds.includes(operation.id))
    .forEach((operation) => {
      const audioIndex = list.findIndex(
        (audio) => audio.group === operation.params.context?.group
      )
      const isAdvancedMode = !operation.params.autoMode
      const isReferenceMode = operation.name === 'MASTERREFERENCE_A'
      const isLegacy = operation.name === 'MASTERING_A'

      if (audioIndex === -1) {
        list.push({
          id: operation.id,
          group: operation.params.context?.group || 1,
          icon: operation.params.autoMode ? 'sparkles' : 'mixer',
          type: getTypeOperation({
            isLegacy,
            isReferenceMode,
            isAdvancedMode
          }),
          name: getNameOperation({
            isLegacy,
            isReferenceMode,
            isAdvancedMode
          }),
          operations: [
            getOperationData({
              operation,
              isAdvancedMode,
              isReferenceMode
            })
          ]
        })
      } else {
        list[audioIndex].operations.push(
          getOperationData({
            operation,
            isAdvancedMode,
            isReferenceMode
          })
        )
      }
    })

  localOperations?.forEach((operation) => {
    const audioIndex = list.findIndex(
      (audio) => audio.group === operation.group
    )
    const isAdvancedMode = !!operation.advancedMode
    const isReferenceMode = !!operation.referenceTrack
    const isLegacy = false

    if (audioIndex === -1) {
      list.push({
        id: v4(),
        group: operation.group,
        icon: operation.autoMode ? 'sparkles' : 'mixer',
        type: getTypeOperation({
          isLegacy,
          isReferenceMode,
          isAdvancedMode
        }),
        name: getNameOperation({
          isLegacy,
          isReferenceMode,
          isAdvancedMode
        }),
        operations: []
      })
    }
  })

  return list
}
