import React, { useCallback, useState } from 'react'
import { FormInput, Modal } from 'interface'
import { Trans, t } from '@lingui/macro'
import { Button } from 'interface/src/components/button'
import styles from './modal-edit-task.module.scss'

interface Props {
  open: boolean
  loading: boolean
  name?: string
  onDismiss(): void
  onSave(i: { name: string }): void
}

export const ModalEditTask: React.FC<Props> = ({
  open,
  loading,
  name,
  onDismiss,
  onSave
}) => {
  const [newName, setNewName] = useState(name || '')

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setNewName(e.target.value),
    []
  )

  const handleSave = useCallback(
    () =>
      onSave({
        name: newName || ''
      }),
    [newName, onSave]
  )

  return (
    <Modal
      label="modal-edit-task-mastering"
      isOpen={open}
      onClose={onDismiss}
      targetId="mastering-app"
      shouldCloseOnOverlayClick
      modalClassName={styles.modal}
    >
      <div className={styles.container}>
        <p className={styles.title}>
          <Trans>Rename</Trans>
        </p>

        <div className={styles.content}>
          <FormInput
            maxLength={140}
            value={newName || ''}
            error={!newName?.length}
            className={styles.input}
            onChange={onChange}
            placeholder={t`Rename`}
          />
        </div>

        <div className={styles.actions}>
          <Button color="light" variant="outline" onClickAction={onDismiss}>
            <Trans>Cancel</Trans>
          </Button>

          <Button
            onClickAction={handleSave}
            disabled={loading || !newName?.length}
          >
            {loading ? <Trans>Loading...</Trans> : <Trans>Save</Trans>}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
