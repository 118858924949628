import { Trans } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import { Icon } from 'interface'
import { Button } from 'interface/src/components/button'
import { useRouter } from 'next/router'
import { trigger } from '../../../lib/events'
import styles from './delete-task.module.scss'
import { ModalDeleteTask } from './modal-delete-task'
import { useDeleteTask } from '../../../hooks/tasks/use-delete-task'
import { useEvent } from '../../../hooks/analytics'

interface Props {
  taskId?: string
  eventCustomAttributes?: {
    [key: string]: any
  }
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  closeDropdown?: () => void
  name?: string
}

export const DeleteTask: React.FC<Props> = ({
  taskId,
  eventCustomAttributes,
  setModalIsOpen,
  closeDropdown,
  name
}) => {
  const { sendEvent } = useEvent()
  const { pathname, push } = useRouter()
  const { deleteTask, loading } = useDeleteTask()

  const [openModal, setOpenModal] = useState(false)

  const onOpenModal = useCallback(() => {
    setModalIsOpen?.(true)
    setOpenModal(true)
    sendEvent({
      name: 'mastering-project-options-activity',
      category: 'engagement',
      customAttributes: {
        activity: 'Delete',
        ...eventCustomAttributes
      }
    })
  }, [setModalIsOpen, sendEvent, eventCustomAttributes])

  const onDismissModal = useCallback(() => {
    setModalIsOpen?.(false)
    setOpenModal(false)
  }, [setModalIsOpen])

  const onConfirm = useCallback(async () => {
    if (taskId) {
      await deleteTask({
        trackId: taskId
      })

      sendEvent({
        name: 'mastering-project-deleted',
        category: 'engagement',
        customAttributes: {
          mastered_project_deleted: name,
          ...eventCustomAttributes
        }
      })

      if (pathname !== '/') {
        push('/')
      } else {
        trigger('revalidate:tasks')
      }
    }
    onDismissModal()
    closeDropdown?.()
  }, [
    taskId,
    onDismissModal,
    deleteTask,
    pathname,
    push,
    closeDropdown,
    eventCustomAttributes,
    sendEvent,
    name
  ])

  return (
    <div className={styles.container}>
      <Button
        color="light"
        variant="text"
        classes="!font-normal px-4 py-2 whitespace-nowrap"
        onClickAction={onOpenModal}
      >
        <Icon name="trash" width={16} height={16} />
        <Trans>Delete project</Trans>
      </Button>

      {openModal && (
        <ModalDeleteTask
          loading={loading}
          open={openModal}
          onDismiss={onDismissModal}
          onConfirm={onConfirm}
          title={<Trans>Are you sure you want to delete this project?</Trans>}
          info={
            <Trans>
              This project and any mastered tracks will be permanently deleted.
            </Trans>
          }
          image="ic-alert"
        />
      )}
    </div>
  )
}
