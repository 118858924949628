import {
  OperationMasteringLegacyParams,
  OperationMasteringLegacyResult,
  TaskOperations,
  TaskSummary,
  WorkflowName
} from '../../types/task.types'

export const masterOperations: WorkflowName[] = [
  'MASTER_A',
  'MASTERREFERENCE_A',
  'MASTERING_A'
]

export const formatSeconds = (seconds?: number): string => {
  if (!seconds) return ''
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

export const getDuration = (summary?: TaskSummary): string | undefined => {
  return summary?.v1?.original?.duration
    ? `${formatSeconds(summary.v1.original.duration)}`
    : undefined
}

export const getBitrate = (summary?: TaskSummary): string | undefined => {
  return summary?.v1?.original?.bitRate
    ? `${Math.round(summary.v1.original.bitRate / 1000)} kbps`
    : undefined
}

export const getSampleRate = (summary?: TaskSummary): string | undefined => {
  return summary?.v1?.original?.sampleRate
    ? `${summary.v1.original.sampleRate / 1000} kHz`
    : undefined
}

export const normalizeLegacyData = (
  operation: TaskOperations
): TaskOperations => {
  if (operation.name !== 'MASTERING_A') {
    return operation
  }

  const result = operation.result as OperationMasteringLegacyResult
  const params = operation.params as any as OperationMasteringLegacyParams

  const normalized = {
    ...operation,
    result: {
      files: {
        mastered_m4a: result.files?.mastered
      }
    },
    params: {
      context: params?.context,
      targetLoudness: params?.targetLufs,
      referenceAudio: params?.referenceAudio
    }
  }

  return normalized
}
