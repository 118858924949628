import { Trans } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import { Icon } from 'interface'
import { Button } from 'interface/src/components/button'
import { trigger } from '../../../lib/events'
import styles from './edit-task.module.scss'
import { ModalEditTask } from './modal-edit-task'
import { useUpdateTrack } from '../../../hooks/tasks/use-update-track'
import { useEvent } from '../../../hooks/analytics'

interface Props {
  name?: string
  taskId?: string
  eventCustomAttributes?: {
    [key: string]: any
  }
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  closeDropdown?: () => void
}

export const EditTask: React.FC<Props> = ({
  name,
  taskId,
  eventCustomAttributes,
  setModalIsOpen,
  closeDropdown
}) => {
  const { sendEvent } = useEvent()
  const { updateTrack, loading } = useUpdateTrack()

  const [openModal, setOpenModal] = useState(false)

  const onOpenModal = useCallback(() => {
    setModalIsOpen?.(true)
    setOpenModal(true)
    sendEvent({
      name: 'mastering-project-options-activity',
      category: 'engagement',
      customAttributes: {
        activity: 'Rename',
        ...eventCustomAttributes
      }
    })
  }, [setModalIsOpen, sendEvent, eventCustomAttributes])

  const onDismissModal = useCallback(() => {
    setModalIsOpen?.(false)
    setOpenModal(false)
  }, [setModalIsOpen])

  const onUpdateTask = useCallback(
    async (i: { name: string }) => {
      if (taskId) {
        await updateTrack({
          trackId: taskId,
          name: i.name
        })
      }
      onDismissModal()
      trigger('revalidate:tasks')
      closeDropdown?.()
    },
    [taskId, onDismissModal, updateTrack, closeDropdown]
  )

  return (
    <div className={styles.container}>
      <Button
        color="light"
        variant="text"
        classes="!font-normal px-4 py-2 w-full !justify-start"
        onClickAction={onOpenModal}
      >
        <Icon name="edit" width={16} height={16} />
        <Trans>Rename</Trans>
      </Button>

      {openModal && (
        <ModalEditTask
          name={name}
          loading={loading}
          open={openModal}
          onDismiss={onDismissModal}
          onSave={onUpdateTask}
        />
      )}
    </div>
  )
}
