import { DropDown, Icon, useOnClickOutside } from 'interface'
import React, { useRef, useState, RefObject, useCallback, useMemo } from 'react'
import { Button } from 'interface/src/components/button'
import { EditTask } from '../../modules/task/edit-task'
import { DeleteTask } from '../../modules/task/delete-task'
import { TaskOperations } from '../../types/task.types'
import { getProcessedAudios } from '../../modules/project/project-page/utils'
import { useEvent } from '../../hooks/analytics'

type Props = {
  id: string
  name: string
  operations?: TaskOperations[]
  visible?: boolean
}

export const DropDownProjectList: React.FC<Props> = ({
  id,
  name,
  operations,
  visible = false
}) => {
  const { sendEvent } = useEvent()
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const ref: RefObject<any> = useRef()

  const eventCustomAttributes = useMemo(() => {
    if (!operations) return {}

    const processed = getProcessedAudios({
      operations
    })

    const conversions = processed.length
    const types = Array.from(new Set(processed.map(({ type }) => type)))?.join(
      ', '
    )

    return {
      project_ID: id,
      mastering_types: types,
      count_mastering_conversions: conversions
    }
  }, [id, operations])

  const closeDropdown = useCallback(() => setIsActionDropdownOpen(false), [])

  useOnClickOutside(ref, () => {
    if (!modalIsOpen) {
      closeDropdown()
    }
  })

  const ignoreDropdownClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
    []
  )

  const onOpenDropdown = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsActionDropdownOpen(true)

      sendEvent({
        name: 'mastering-project-options-clicked',
        category: 'engagement',
        customAttributes: {
          ...eventCustomAttributes
        }
      })
    },
    [sendEvent, setIsActionDropdownOpen, eventCustomAttributes]
  )

  return (
    <>
      <Button
        classes={`${
          visible ? 'visible' : isActionDropdownOpen ? 'visible' : 'invisible'
        } group-hover/item:visible px-3 py-0`}
        size="xs"
        color="light"
        variant="text"
        onClickAction={onOpenDropdown}
      >
        <Icon name="more" width={20} height={20} />
      </Button>
      {isActionDropdownOpen && (
        <span
          role="presentation"
          ref={ref}
          onClick={ignoreDropdownClick}
          className="absolute top-7 right-2 z-50"
        >
          <DropDown>
            <EditTask
              taskId={id}
              name={name}
              setModalIsOpen={setModalIsOpen}
              closeDropdown={closeDropdown}
              eventCustomAttributes={eventCustomAttributes}
            />
            <DeleteTask
              taskId={id}
              name={name}
              setModalIsOpen={setModalIsOpen}
              closeDropdown={closeDropdown}
              eventCustomAttributes={eventCustomAttributes}
            />
          </DropDown>
        </span>
      )}
    </>
  )
}
